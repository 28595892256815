<template>
  <div class="auth-page">
    <header class="page__header header">
      <div class="header__logo">
        <img :src="require('@/assets/images/logo.svg')" alt="Пружинный проект">
      </div>
    </header>

    <div class="page__content">
      <div class="page__block">
        <VForm
          @request="handleSubmit"
          class="login-form p-m pb-l mb-30"
        >
          <p class="login-form__title mb-m">Войдите в систему</p>

          <VInput
            label="Логин:"
            class="mb-30"
            name="username"
            required
            :minLength="minLengthLogin"
          >
            <Validation for="required">Поле обязательно</Validation>
            <Validation for="minLength">Минимальное количество символов {{ minLengthLogin }}</Validation>
          </VInput>
          <VInput
            label="Пароль:"
            class="mb-30"
            name="password"
            type="password"
            required
            :minLength="minLengthPassword"
          >
            <Validation for="required">Поле обязательно</Validation>
            <Validation for="minLength">Минимальное количество символов {{ minLengthPassword }}</Validation>
          </VInput>

          <VButton
            text="Войти"
            class="m-auto"
            type="submit"
            color="primary"
            size="l"
            :loading="isLoading"
          />
        </VForm>

        <div class="auth-page__text">
          <p class="text mb-m">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          </p>
          <p class="text mb-m">
            Используя данный ресурс, Вы соглашаетесь с условиями <a href="!#" class="link">«Положения о конфиденциальности»</a>
          </p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import VInput from '@/components/Form/Vinput/VInput'
import VForm from '@/components/Form/VForm'
import VButton from '@/components/simple/button/VButton'
import Validation from '@/components/Form/Validation'

import { error } from '@/utils/error'
import { showAlertWarning } from '@/utils/store/alert'

export default {
  components: { VButton, Validation, VInput, VForm },

  data () {
    return {
      isLoading: false,

      minLengthLogin: '3',
      minLengthPassword: '6'
    }
  },

  mounted () {
    if (this.$route.query.message) {
      showAlertWarning(error(this.$route.query.message))
    }
  },

  methods: {
    async handleSubmit (values) {
      try {
        this.isLoading = true
        await this.$store.dispatch('login', values)
        if (this.$store.getters.currentUserRole === 'brigadier' || this.$store.getters.currentUserRole === 'production-master' || this.$store.getters.currentUserRole === 'executor') {
          await this.$router.push({ name: 'LaunchOrderList' })
        } else await this.$router.push({ name: 'OrderListItem' })
      } finally {
        this.isLoading = false
        location.reload()
      }
    }
  }
}
</script>
